import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomInput from "./CustomInput";
import { get, post, put, _delete } from "./fetch";
import LoadingButton from "./LoadingButton";
import { Typeahead } from "react-bootstrap-typeahead";

const ProductEdit = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [sku, setSku] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [costPrice, setCostPrice] = useState("");
  const [stock, setStock] = useState("");
  const [selectedProductTags, setSelectedProductTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productTags, setProductTags] = useState([]);
  const { uuid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    get(`/inventory/product-tags`).then((data) => {
      setProductTags(data);
    });
  }, []);

  useEffect(() => {
    uuid &&
      get(`/inventory/products/${uuid}`).then((data) => {
        setName(data.name || "");
        setDescription(data.description || "");
        setSku(data.sku || "");
        setSalePrice(data.salePrice || "");
        setCostPrice(data.costPrice || "");
        setStock(data.stock || "");
        setSelectedProductTags(data.tags || []);
      });
  }, [uuid]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const obj = {
      name: name,
      description: description,
      sku: sku,
      salePrice: salePrice,
      costPrice: costPrice || 0,
      stock: stock || 0,
      productTags: selectedProductTags.map((it) =>
        !!it.label ? it.label : it,
      ),
    };

    setLoading(true);
    if (uuid) {
      put(`/inventory/products/${uuid}`, obj)
        .then((_) => {
          navigate("/products");
        })
        .finally((_) => setLoading(false));
    } else {
      post("/inventory/products", obj)
        .then((_) => {
          setName("");
          setDescription("");
          setSku("");
          setSalePrice("");
          setCostPrice("");
          setStock("");
          setSelectedProductTags([]);
        })
        .finally((_) => setLoading(false));
    }
  };

  const deleteProduct = () => {
    _delete(`/inventory/products/${uuid}`).then((_) => navigate("/products"));
  };

  return (
    <Row className="h-100 gx-0 align-items-center px-2 mt--7">
      <Col xs={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
        <Card>
          <Card.Body>
            <Card.Title>{!!uuid ? "Измени" : "Креирај"} продукт</Card.Title>
            <Form onSubmit={handleSubmit}>
              <Row>
                <CustomInput
                  label={"Име"}
                  value={name}
                  setValue={setName}
                  options={{ required: true }}
                />
                <CustomInput
                  label={"Опис"}
                  value={description}
                  setValue={setDescription}
                />
                <CustomInput label={"Шифра"} value={sku} setValue={setSku} />
                <CustomInput
                  label={"Продажна цена"}
                  type="number"
                  value={salePrice}
                  setValue={setSalePrice}
                  asCol={true}
                  options={{ required: true }}
                />
                <CustomInput
                  label={"Набавна цена"}
                  type="number"
                  value={costPrice}
                  setValue={setCostPrice}
                  asCol={true}
                />
                <CustomInput
                  label={"Залиха"}
                  type="number"
                  value={stock}
                  setValue={setStock}
                  asCol={true}
                />
                <Form.Group xs={12} sm={12} md={12} as={Col} className="mb-3">
                  <Form.Label>Категории</Form.Label>
                  <Typeahead
                    id="productTags"
                    multiple
                    placeholder="Избери категории..."
                    onChange={(selected) => {
                      setSelectedProductTags([...selected]);
                    }}
                    options={productTags}
                    selected={selectedProductTags}
                    allowNew={true}
                  />
                </Form.Group>
              </Row>
              <div className="d-flex">
                <LoadingButton
                  loading={loading}
                  text={!!uuid ? "Измени" : "Креирај"}
                />
                <Button
                  className="ms-2"
                  variant="secondary"
                  as={Link}
                  to="/products"
                >
                  Откажи
                </Button>
                <Button
                  onClick={() => deleteProduct()}
                  variant="danger"
                  className="ms-auto px-2 py-1"
                >
                  Избриши
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ProductEdit;
