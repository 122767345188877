import { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Container,
  Form,
  InputGroup,
  Modal,
  NavLink,
  Table,
} from "react-bootstrap";
import { get, post } from "./fetch";
import { Plus, ShoppingCart } from "react-feather";
import { Link } from "react-router-dom";
import { useCartActionsContext } from "./CartContext";
import toast, { CheckmarkIcon, ErrorIcon } from "react-hot-toast";

const ProductModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <span className="fw-light">Продукт</span> {props.product.uuid}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Име на продукт: <span className="fw-bold">{props.product.name}</span>
        </p>
        <p>
          Опис на продукт:{" "}
          <span className="fw-bold">{props.product.description}</span>
        </p>
        <p>
          Шифра: <span className="fw-bold">{props.product.sku}</span>
        </p>
        <p>
          Продажна цена:{" "}
          <span className="fw-bold">
            {props.product.costPrice.toLocaleString("mk-MK", {
              style: "currency",
              currency: "MKD",
            })}
          </span>
        </p>
        <p>
          Набавна цена:{" "}
          <span className="fw-bold">
            {props.product.costPrice.toLocaleString("mk-MK", {
              style: "currency",
              currency: "MKD",
            })}
          </span>
        </p>
        <p>
          Залиха: <span className="fw-bold">{props.product.stock}</span>
        </p>
        <p>
          Категории:{" "}
          <span className="fw-bold">
            {props.product.tags.map((item) => (
              <Badge className="me-1" key={item}>
                {item}
              </Badge>
            ))}
          </span>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Затвори</Button>
      </Modal.Footer>
    </Modal>
  );
};

const Products = () => {
  const [text, setText] = useState("");
  const [searchMode, setSearchMode] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [items, setItems] = useState([]);
  const { increaseItems } = useCartActionsContext();
  const [modalShow, setModalShow] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});

  const selectProduct = (product) => {
    setSelectedProduct(product);
    setModalShow(true);
  };

  const clearSelectedProduct = () => {
    setSelectedProduct({});
    setModalShow(false);
  };

  const addItemToCart = (itemUuid) => {
    post("/inventory/cart", { productUuid: itemUuid, quantity: 1 }).then(
      (_) => {
        increaseItems();
        toast.success("Продуктот е успешно додаден");
      },
    );
  };

  const empty = () => {
    return searchMode ? filteredItems.length === 0 : items.length === 0;
  };

  useEffect(() => {
    get("/inventory/products").then((data) => {
      setItems(data);
    });
  }, []);

  const search = (term) => {
    if (!!term.trim()) {
      setText(term);
      setSearchMode(!!term);
      setFilteredItems(
        items.filter((item) =>
          ["name", "description"].reduce(
            (prev, curr) =>
              prev || item[curr].toLowerCase().includes(term.toLowerCase()),
            false,
          ),
        ),
      );
    } else {
      setText("");
      setSearchMode(false);
      setFilteredItems([]);
    }
  };

  return (
    <>
      <Container className="mt-4" style={{ height: "calc(100% - 10rem)" }}>
        <Form>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Внеси текст..."
              value={text}
              onChange={(e) => search(e.target.value)}
            />
            <Button as={Link} to={"/product"} variant="info" id="button-addon2">
              <Plus size={18} />
              Креирај продукт
            </Button>
          </InputGroup>
        </Form>
        <div style={{ overflow: "auto", height: "calc(100% - 3rem)" }}>
          <Table striped hover>
            <thead>
              <tr>
                <th>Име</th>
                <th>Шифра</th>
                <th>Продажна цена</th>
                <th>Залиха</th>
                <th>Категории</th>
                <th>Акции</th>
              </tr>
            </thead>
            <tbody className="text-left align-middle">
              {(searchMode ? filteredItems : items).map((item) => (
                <tr
                  key={item.uuid}
                  style={{ cursor: "pointer" }}
                  onClick={() => selectProduct(item)}
                >
                  <td className="fw-bold">
                    <NavLink as={Link} to={`/product/${item.uuid}`}>
                      {item.name}
                    </NavLink>
                  </td>
                  <td>{item.sku}</td>
                  <td>
                    {item.salePrice.toLocaleString("mk-MK", {
                      style: "currency",
                      currency: "MKD",
                    })}
                  </td>
                  <td>
                    {item.stock === 0 ? (
                      <ErrorIcon size={16} />
                    ) : (
                      <CheckmarkIcon size={16} />
                    )}
                  </td>
                  <td>
                    {item.tags.map((item) => (
                      <Badge className="me-1" key={item}>
                        {item}
                      </Badge>
                    ))}
                  </td>
                  <td>
                    {
                      <Button
                        onClick={(ev) => {
                          ev.stopPropagation();
                          addItemToCart(item.uuid);
                        }}
                        variant="primary"
                        size="sm"
                        className="px-3 py-2"
                      >
                        <ShoppingCart size={16} />
                      </Button>
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {empty() && <p className="text-center">Не се пронајдени продукти</p>}
        </div>
      </Container>
      {selectedProduct.uuid && (
        <ProductModal
          show={modalShow}
          onHide={() => clearSelectedProduct()}
          product={selectedProduct}
        />
      )}
    </>
  );
};

export default Products;
