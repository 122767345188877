const messages = {
  USER_WRONG_CREDENTIALS: "Погрешно корисничко име или лозинка",
  GENERIC: "Oops. Something went wrong",
};

const get = (key) => {
  return messages[key] || messages["GENERIC"];
};

const errors = { get };

export { errors };
