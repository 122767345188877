import { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  InputGroup,
  Modal,
  Table,
} from "react-bootstrap";
import { get } from "./fetch";
import { BlobProvider } from "@react-pdf/renderer";
import InvoicePdf from "./InvoicePdf";

const InvoiceModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <span className="fw-light">Фактура</span> {props.invoice.uuid}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Име на клиент:{" "}
          <span className="fw-bold">{props.invoice.buyerName}</span>
        </p>
        <p>
          Телефон на клиент:{" "}
          <span className="fw-bold">{props.invoice.buyerPhoneNumber}</span>
        </p>
        <p>
          Вкупно:{" "}
          <span className="fw-bold">
            {props.invoice.total.toLocaleString("mk-MK", {
              style: "currency",
              currency: "MKD",
            })}{" "}
            <span style={{ fontSize: "0.7em" }}>
              ({props.invoice.discount}% попуст)
            </span>
          </span>
        </p>
        <Table striped hover size="sm">
          <thead>
            <tr>
              <th>Продукт</th>
              <th>Вкупна цена</th>
              <th>Количина</th>
            </tr>
          </thead>
          <tbody className="text-left align-middle">
            {props.invoice.items.map((item) => (
              <tr key={item.uuid}>
                <td>{item.name}</td>
                <td>
                  {item.price.toLocaleString("mk-MK", {
                    style: "currency",
                    currency: "MKD",
                  })}
                </td>
                <td>{item.quantity}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <BlobProvider document={<InvoicePdf invoice={props.invoice} />}>
          {({ url, blob }) => (
            <Button href={url} target="_blank">Превземи</Button>
          )}
        </BlobProvider>
        <Button onClick={props.onHide}>Затвори</Button>
      </Modal.Footer>
    </Modal>
  );
};

const Invoices = () => {
  const [text, setText] = useState("");
  const [searchMode, setSearchMode] = useState(false);
  const [filteredItems, setFilteredItems] = useState([]);
  const [items, setItems] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState({});

  const empty = () => {
    return searchMode ? filteredItems.length === 0 : items.length === 0;
  };

  const selectInvoice = (invoice) => {
    setSelectedInvoice(invoice);
    setModalShow(true);
  };

  const clearSelectedInvoice = () => {
    setSelectedInvoice({});
    setModalShow(false);
  };

  useEffect(() => {
    get("/inventory/invoices").then((data) => {
      setItems(data);
    });
  }, []);

  const search = (term) => {
    if (!!term.trim()) {
      setText(term);
      setSearchMode(!!term);
      setFilteredItems(
        items.filter((item) =>
          ["buyerName", "buyerPhoneNumber"].reduce(
            (prev, curr) =>
              prev || item[curr].toLowerCase().includes(term.toLowerCase()),
            false,
          ),
        ),
      );
    } else {
      setText("");
      setSearchMode(false);
      setFilteredItems([]);
    }
  };

  return (
    <>
      <Container className="mt-4" style={{ height: "calc(100% - 10rem)" }}>
        <Form>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Внеси текст..."
              value={text}
              onChange={(e) => search(e.target.value)}
            />
          </InputGroup>
        </Form>
        <div style={{ overflow: "auto", height: "calc(100% - 3rem)" }}>
          <Table striped hover>
            <thead>
              <tr>
                <th>Име на клиент</th>
                <th>Телефон на клиент</th>
                <th>Вкупна цена</th>
                <th>Креирана на</th>
              </tr>
            </thead>
            <tbody className="text-left align-middle">
              {(searchMode ? filteredItems : items).map((item) => (
                <tr
                  key={item.uuid}
                  style={{ cursor: "pointer" }}
                  onClick={() => selectInvoice(item)}
                >
                  <td>{item.buyerName}</td>
                  <td>{item.buyerPhoneNumber}</td>
                  <td>
                    {item.total?.toLocaleString("mk-MK", {
                      style: "currency",
                      currency: "MKD",
                    })}{" "}
                  </td>
                  <td>{new Date(item.createdAt).toLocaleString("mk-MK")}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          {empty() && <p className="text-center">Не се пронајдени фактури</p>}
        </div>
      </Container>
      {selectedInvoice.uuid && (
        <InvoiceModal
          show={modalShow}
          onHide={() => clearSelectedInvoice()}
          invoice={selectedInvoice}
        />
      )}
    </>
  );
};

export default Invoices;
