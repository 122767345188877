import React, { createContext, useContext, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

const UserContext = createContext();
const UserActionsContext = createContext();

export const useUserContext = () => useContext(UserContext);
export const useUserActionsContext = () => useContext(UserActionsContext);

const UserContextProvider = (props) => {
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const init = () => {
    const token = localStorage.getItem("access_token");
    try {
      setUser(jwtDecode(token));
    } catch (err) {
      localStorage.clear();
      navigate("/login");
    }
  };
  const login = (token) => {
    try {
      setUser(jwtDecode(token));
      localStorage.setItem("access_token", token);
    } catch (err) {}
  };
  const logout = () => {
    setUser({});
    localStorage.clear();
    navigate("/login");
  };

  return (
    <UserContext.Provider value={user}>
      <UserActionsContext.Provider value={{ init, login, logout }}>
        {props.children}
      </UserActionsContext.Provider>
    </UserContext.Provider>
  );
};

export default UserContextProvider;
