import { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { get, post, _delete } from "./fetch";
import { Trash } from "react-feather";
import CustomInput from "./CustomInput";
import { useCartActionsContext } from "./CartContext";

const Cart = () => {
  const [buyerName, setBuyerName] = useState("");
  const [buyerPhoneNumber, setBuyerPhoneNumber] = useState("");
  const [discount, setDiscount] = useState("");
  const [cartItems, setCartItems] = useState([]);
  const { setItems, decreaseItems } = useCartActionsContext();

  const getCart = () => {
    get("/inventory/cart").then((data) => {
      setCartItems(data);
      setItems(data.length);
    });
  };

  const deleteItemFromCart = (uuid) => {
    _delete(`/inventory/cart/${uuid}`).then((_) => {
      get("/inventory/cart").then((data) => {
        setCartItems(data);
      });
      decreaseItems();
    });
  };

  const cartCheckout = (e) => {
    e.preventDefault();
    post("/inventory/cart/checkout", {
      buyerPhoneNumber: buyerPhoneNumber,
      buyerName: buyerName,
    }).then((_) => getCart());
  };

  const empty = () => {
    return cartItems.length === 0;
  };

  const totalCost = () => {
    const cost = cartItems.reduce(
      (prev, curr) => prev + curr.product.salePrice * curr.quantity,
      0,
    );
    return Math.ceil(cost - ((discount || 0) * cost) / 100);
  };

  useEffect(() => {
    getCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container className="mt-4" style={{ height: "calc(100% - 10rem)" }}>
        <div style={{ overflow: "auto", maxHeight: "calc(100% - 22rem)" }}>
          <Table striped hover>
            <thead>
              <tr>
                <th>Име</th>
                <th>Опис</th>
                <th>Цена</th>
                <th>Количина</th>
                <th>Акции</th>
              </tr>
            </thead>
            <tbody className="text-left align-middle">
              {cartItems.map((cartItem) => (
                <tr key={cartItem.uuid}>
                  <td>{cartItem.product.name}</td>
                  <td>{cartItem.product.description}</td>
                  <td>
                    {cartItem.product.salePrice.toLocaleString("mk-MK", {
                      style: "currency",
                      currency: "MKD",
                    })}
                  </td>
                  <td>{cartItem.quantity}</td>
                  <td>
                    {
                      <ButtonGroup>
                        <Button
                          onClick={() => deleteItemFromCart(cartItem.uuid)}
                          variant="danger"
                          size="sm"
                          className="px-3 py-2"
                        >
                          <Trash size={16} />
                        </Button>
                      </ButtonGroup>
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {empty() && <p className="text-center">Кошничката е празна</p>}
        </div>
        {cartItems.length > 0 && (
          <Form onSubmit={cartCheckout}>
            <Row>
              <CustomInput
                label="Име на клиент"
                value={buyerName}
                setValue={setBuyerName}
                asCol={true}
                options={{ required: true }}
                xs={12}
                sm={6}
                md={3}
              />
              <CustomInput
                label="Телефон на клиент"
                value={buyerPhoneNumber}
                setValue={setBuyerPhoneNumber}
                asCol={true}
                options={{ required: true }}
                xs={12}
                sm={6}
                md={3}
              />
              <CustomInput
                label="Попуст (%)"
                type="number"
                value={discount}
                setValue={setDiscount}
                asCol={true}
                xs={12}
                sm={6}
                md={3}
              />
              <Col xs={12} sm={6} md={3} className="align-items-center">
                <p className="mb-2">
                  Вкупно:{" "}
                  {totalCost().toLocaleString("mk-MK", {
                    style: "currency",
                    currency: "MKD",
                  })}
                </p>
                <Button variant="primary" id="button-addon2" type="submit">
                  Нарачај
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Container>
    </>
  );
};

export default Cart;
