import toast from "react-hot-toast";
import { errors } from "./errors";
import { redirect } from "react-router-dom";

const API_URL =
  (process.env.REACT_APP_API_BASE_URL || "http://localhost:8080") + "/api";

const access_token = () => {
  const token = localStorage.getItem("access_token");
  if (token) {
    return {
      Authorization: `Bearer ${token}`,
    };
  }
};

const get = (url) => {
  const options = {
    headers: {
      ...access_token(),
    },
  };
  return fetch(API_URL + url, options).then(handleResponse);
};

const post = (url, payload, form = false) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": !form
        ? "application/json"
        : "application/x-www-form-urlencoded",
      ...access_token(),
    },
    body: constructBody(payload, form),
  };
  return fetch(API_URL + url, options).then(handleResponse);
};

const constructBody = (payload, form) => {
  if (form) {
    var body = new URLSearchParams();
    for (const key in payload) {
      body.append(key, payload[key]);
    }
    return body;
  }
  return JSON.stringify(payload);
};

const put = (url, body) => {
  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...access_token(),
    },
    body: JSON.stringify(body),
  };
  return fetch(API_URL + url, options).then(handleResponse);
};

const _delete = (url) => {
  const options = {
    method: "DELETE",
    headers: {
      ...access_token(),
    },
  };
  return fetch(API_URL + url, options).then(handleResponse);
};

const handleResponse = (res) => {
  return res.text().then((d) => {
    const data = d && JSON.parse(d);
    if (!res.ok) {
      const error = (data && data.message) || res.statusText;

      if (res.status === 401) {
        localStorage.clear();
        redirect("/login");
        window.location.reload();
      }
      toast.error(errors.get(error));
      return Promise.reject(error);
    }

    return data;
  });
};

export { get, post, put, _delete };
