import { useState } from "react";
import { Badge, Container, Nav, Navbar as BootNavbar } from "react-bootstrap";
import { ShoppingCart } from "react-feather";
import { Link } from "react-router-dom";
import { useCartContext } from "./CartContext";
import { useUserActionsContext, useUserContext } from "./UserContext";

const Navbar = () => {
  const [expanded, setExpanded] = useState(false);
  const cartContext = useCartContext();
  const user = useUserContext();
  const { logout } = useUserActionsContext();

  return (
    <BootNavbar expanded={expanded} bg="light" expand="lg" className="py-3">
      <Container>
        <BootNavbar.Brand as={Link} to={"/"}>
          <img
            src="logo.png"
            height="50"
            className="d-inline-block align-top"
            alt=""
          />
        </BootNavbar.Brand>
        <BootNavbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <BootNavbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {user.sub && (
              <>
                <Nav.Link
                  onClick={() => setExpanded(false)}
                  as={Link}
                  to={"/products"}
                >
                  Продукти
                </Nav.Link>
                <Nav.Link
                  onClick={() => setExpanded(false)}
                  as={Link}
                  to={"/invoices"}
                >
                  Фактури
                </Nav.Link>
                {/* <Nav.Link as={Link} to={"/settings"}>Подесувања</Nav.Link> */}
              </>
            )}
          </Nav>
          {user.sub && (
            <Nav>
              <Nav.Link
                onClick={() => setExpanded(false)}
                style={{ position: "relative" }}
                as={Link}
                to={"/cart"}
              >
                <ShoppingCart />{" "}
                <Badge
                  pill={true}
                  bg="danger"
                  style={{
                    position: "absolute",
                    top: "0.1rem",
                    marginLeft: "-0.5rem",
                  }}
                >
                  {cartContext.items}
                </Badge>
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  logout();
                  setExpanded(false);
                }}
              >
                ОДЈАВА
              </Nav.Link>
            </Nav>
          )}
          {!user.sub && (
            <Nav>
              <Nav.Link as={Link} to={"/login"}>
                Најава
              </Nav.Link>
            </Nav>
          )}
        </BootNavbar.Collapse>
      </Container>
    </BootNavbar>
  );
};

export default Navbar;
