import { Button, Spinner } from "react-bootstrap";

const LoadingButton = ({ loading, variant, text }) => {
  return (
    <Button variant={variant || "primary"} type="submit" disabled={loading}>
      {loading && <Spinner animation="border" size="sm" />} {text}
    </Button>
  );
};

export default LoadingButton;
