import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import Entry from "./Entry";
import CartContextProvider from "./CartContext";
import UserContextProvider from "./UserContext";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <UserContextProvider>
        <CartContextProvider>
          <Entry />
        </CartContextProvider>
      </UserContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
