import React, { createContext, useContext, useState } from "react";

const CartContext = createContext({});
const CartActionsContext = createContext();

export const useCartContext = () => useContext(CartContext);
export const useCartActionsContext = () => useContext(CartActionsContext);

const CartContextProvider = (props) => {
  const [items, setItems] = useState(0);
  const increaseItems = () => {
    setItems(items + 1);
  };
  const decreaseItems = () => {
    setItems(items - 1);
  };

  return (
    <CartContext.Provider value={{ items }}>
      <CartActionsContext.Provider
        value={{ setItems, increaseItems, decreaseItems }}
      >
        {props.children}
      </CartActionsContext.Provider>
    </CartContext.Provider>
  );
};

export default CartContextProvider;
