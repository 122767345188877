import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import Login from "./Login";
import Navbar from "./Navbar";
import Products from "./Products";
import ProductEdit from "./ProductEdit";
import Cart from "./Cart";
import Invoices from "./Invoices";
import Settings from "./Settings";
import { useCartActionsContext } from "./CartContext";
import { useEffect } from "react";
import { get } from "./fetch";
import { useUserActionsContext, useUserContext } from "./UserContext";
import { Toaster } from "react-hot-toast";

const ProtectedRoute = ({ user }) => {
  if (!user.uuid && !localStorage.getItem("access_token")) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

const Entry = () => {
  const { setItems } = useCartActionsContext();
  const user = useUserContext();
  const { init } = useUserActionsContext();

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    user.uuid && get("/cart").then((data) => setItems(data.length));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Navigate to="/products" replace />} />
        <Route element={<ProtectedRoute user={user} />}>
          <Route path="/products" element={<Products />} />
          <Route path="/product" element={<ProductEdit />} />
          <Route path="/product/:uuid" element={<ProductEdit />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/invoices" element={<Invoices />} />
          <Route path="/settings" element={<Settings />} />
        </Route>
        <Route path="/login" element={<Login />} />
      </Routes>
      <Toaster
        toastOptions={{
          style: {
            background: "#1a1a1a",
            color: "#fff",
          },
        }}
      />
    </>
  );
};

export default Entry;
